<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn></template
    >
    <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
  </static-fullscreen-card>
</template>

<script>
export default {
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.sales.list,
      url: "/mechti/objects/broker",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: { status_fin: 1 },
        paramName: "sales",
      },
    };
  },
  created() {
    this.$root.title = "Продажи";
  },
  methods: {
    onClickRow(d) {
      this.$router.push({ name: "sales_view", params: { id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
  },
};
</script>
